import {Component} from '@angular/core';

import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {RequestService} from "./services/request.service";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {

    loaded: boolean = false;

    public selectedIndex = 0;
    public appPages = [
        {
            title: 'Home',
            url: '',
            icon: 'home'
        },
        {
            title: 'Profile',
            url: '/profile',
            icon: 'paper-plane'
        },
        {
            title: 'Events',
            url: '/events',
            icon: 'paper-plane'
        },
    ];

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        requestService: RequestService
    ) {
        this.initializeApp();
        requestService.initialize().then(() => {
            this.loaded = true;
        });
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    ngOnInit() {
        const path = window.location.pathname.split('/')[1];
        if (path !== undefined) {
            this.selectedIndex = this.appPages.findIndex(page =>  page.url.toLowerCase() === path.toLowerCase());
        }
    }
}
