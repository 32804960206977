import {Injectable} from '@angular/core';
import axios, {AxiosInstance} from "axios";
import {ModalController} from "@ionic/angular";
import {LoginComponent} from "../login/login.component";
import {Storage} from "@ionic/storage";

@Injectable({
    providedIn: 'root'
})
export class RequestService {

    private _axios: AxiosInstance;
    private _token: string;

    constructor(private modalCtrl: ModalController, private storage: Storage) {
        this.createAxios();
    }

    public get baseURL() {
        let baseURL: string = window.location.origin + '/api/v1';
        if (window.location.hostname === 'localhost' || window.location.hostname.startsWith('127.')) {
            baseURL = "http://localhost:8080/api/v1"
        }
        return baseURL;
    }

    public get loginBaseURL(){
        let baseURL: string = window.location.origin + '/api/v1';
        if (window.location.hostname === 'localhost' || window.location.hostname.startsWith('127.')) {
            baseURL = "http://localhost:8080/api/v1"
        }
        return baseURL;
    }

    public get defaultAxios() {
        return axios;
    }

    public get axios() {
        return this._axios;
    }

    async initialize() {
        let token = await this.storage.get('token');
        if (token) {
            if (await this.checkToken(token)) {
                this._token = token;
                return;
            }
        }
        await this.getToken();
    }

    async checkToken(token: string): Promise<boolean> {
        if (!token) {
            token = this._token;
        }
        try {
            await axios.get(`${this.loginBaseURL}/login`, {
                headers: {
                    Token: token
                }
            });
            return true;
        } catch (e) {
            return false;
        }
    }

    async getToken() {
        let modal = await this.modalCtrl.create({
            component: LoginComponent,
            backdropDismiss: false,
            showBackdrop: true
        });
        await modal.present();
        let {data} = await modal.onDidDismiss();
        this._token = data.token;
        await this.storage.set('token', this._token);
    }

    private createAxios() {
        this._axios = axios.create({
            baseURL: this.baseURL
        });
        this._axios.interceptors.request.use((config) => {
            config.headers["Token"] = this._token;
            return config;
        });
    }

    public getAxios() {
        return this._axios;
    }

}
