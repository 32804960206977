import {Component, OnInit} from '@angular/core';
import {RequestService} from "../services/request.service";
import {ModalController, ToastController} from "@ionic/angular";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

    private _toast: HTMLIonToastElement;

    constructor(private modalCtrl: ModalController, private requestService: RequestService, private toastCtrl: ToastController) {
    }

    ngOnInit() {
    }

    async onLoginButtonPress(event) {
        event.preventDefault();
        if (this._toast) {
            await this._toast.dismiss();
        }
        let account = event.target.elements.account.value;
        let password = event.target.elements.password.value;
        try {
            let data = await this.requestService.defaultAxios.post(`${this.requestService.loginBaseURL}/login`, {
                account,
                password
            });
            await this.modalCtrl.dismiss({token: data.data.data.token});
        } catch (e) {
            this._toast = await this.toastCtrl.create({message: 'Login failed', position: 'top'});
            await this._toast.present();
        }
    }

}
